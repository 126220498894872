import { createSlice } from "@reduxjs/toolkit";


const predataSlice = createSlice({
    name:'predata',
    initialState:{
        brands: [],
        services: [],
        orders:[]
    },

    reducers: {
        setBrands(state,action){
            state.brands = action.payload;
        },
        setServices(state,action){
            state.services = action.payload;
        },
        setOrders(state,action){

            state.orders = action.payload;
        }
    }
})

export const predataActions = predataSlice.actions;

export default predataSlice;