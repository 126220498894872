import React from 'react'
import Button from './Button'
import Logo from './Logo'
import { useSelector,useDispatch } from 'react-redux'
import { searchActions } from '../Redux/SearchSlice'
import { urls } from '../Strings/urls'
import { uiActions } from '../Redux/UiSlice'
import { customerActions } from '../Redux/CustomerSlice'
import { orderAction } from '../Redux/OrderSlice'
import { notiAction } from '../Redux/notiSlice'
import { spinnerAction } from '../Redux/SpinnerSlice'

const Search = () => {

  const searchState = useSelector(state=>state.search)
  const dispatch = useDispatch();

  const onCellphoneChange = (e) => {
     dispatch(searchActions.setCellphone(e.target.value))
  }

  const onSearch = (e) => {
    e.preventDefault();
    console.log(searchState.cellphone)

    const makeSearch = async () => {

        dispatch(spinnerAction.setIsLoading(true))

        let res =  await fetch(urls.end_point+"/customers?cellphone="+searchState.cellphone);

        let data = await res.json();

        dispatch(spinnerAction.setIsLoading(false))

        if(data.length===0){
            dispatch(uiActions.setIsRegistering())
        }else {

            dispatch(notiAction.showNotification({
                open:true,
                message:"Welcome Back!",
                type: 'success'
            }))

            setTimeout(() => {
                dispatch(notiAction.clearNotification())
            }, 5000);
          
            
            let customer = data[0];
            console.log(customer);
            dispatch(customerActions.setCustomer({
                _id:customer._id,
                fullname: customer.fullname,
                cellphone:customer.cellphone,
                email:customer.email
            }))
            dispatch(orderAction.setCustomer(customer.fullname))
            dispatch(uiActions.setIsOrdering())
        }

        
    }

    makeSearch().catch(error=>{

        dispatch(spinnerAction.setIsLoading(false))
        console.log(error);
        dispatch(notiAction.showNotification({
            open:true,
            message:error.message,
            type: 'error'
        }))

        setTimeout(() => {
            dispatch(notiAction.clearNotification())
        }, 5000);

        
    })

  

  }
  
  return (
    <div className="form-box" style={{marginTop:'3%'}}>
         <div className='text-center'>
         <Logo/>
         </div>
         
        <br/>
        <form>
            <div className="form-group">
            <label htmlFor="cellSearch">CELLPHONE NUMBER</label>
            
            <input className="form-control" id="cellSearch" value={searchState.cellphone} onChange={onCellphoneChange} type="tel" placeholder='081....' name="NameSearch" />
            </div>
            <br/>
            <Button name={'CONTINUE'} action={onSearch}/>
        </form>
    </div>

  )
}

export default Search