
import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
    name: 'customer',
    initialState: {
        
        cellphone:''
        
    },

    reducers: {
        setCellphone(state,action){
            state.cellphone = action.payload;
        },
        
    }

})

export const searchActions = searchSlice.actions;

export default searchSlice;