
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Search from './Components/Search';
import Register from './Components/Register';
import Order from './Components/Order';
import { useSelector,useDispatch } from 'react-redux'
import { useEffect } from 'react';
import { urls } from './Strings/urls';
import { predataActions } from './Redux/preDataSlice';
import Notification from './Components/Notification';
import { Route, Routes } from 'react-router-dom';
import Application from './Components/Application';
import Admin from './Components/Admin';



function App() {
  const uiState = useSelector(state=> state.ui);
  const dispatch = useDispatch();

  const notification = useSelector(state => state.noti.notification)



  useEffect(()=>{
     const getBrands = async ()=> {
      let res = await fetch(urls.end_point+'/brands');
      let data = await res.json();
      dispatch(predataActions.setBrands(data.reverse()))
     }

     getBrands().catch(err=>{
      console.log(err);
     })

  },[dispatch])

  
  useEffect(()=>{
    const getServices = async ()=> {

      let res = await fetch(urls.end_point+'/services');
      let data = await res.json();
      dispatch(predataActions.setServices(data.reverse()))
    }

    getServices().catch(err=>{
      console.log(err);
    })

  },[dispatch])


  return (
      <Routes>
        <Route path='/' element={<Application/>}/>
        <Route path='admin' element={<Admin/>}/>
      </Routes>
  );
}

export default App;
