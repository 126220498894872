import { createSlice } from "@reduxjs/toolkit";


const orderSlice = createSlice({
    name:'order',
    initialState:{
        items: [],
        customer: '',
    },

    reducers: {
        setCustomer(state,action){
            state.customer = action.payload;
        },
        addItem(state,action){

            state.items.push(action.payload)
        },
        removeItem(state,action){
          const id = action.payload;
          state.items = state.items.filter(item=>item.id!==id)
        },
        clearItems(state){
            state.items = [];
        }
    }
})

export const orderAction = orderSlice.actions;

export default orderSlice;