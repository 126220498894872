import React from 'react'
import Button from './Button'
import { useSelector,useDispatch } from 'react-redux'
import { orderItemActions } from '../Redux/OrderItemSlice';
import { orderAction } from '../Redux/OrderSlice';

const OrderForm = () => {


 const predataState = useSelector(state=>state.predata);
 const orderItemState = useSelector(state=>state.orderitem);

 const dispatch = useDispatch();

 const onBrandChange = e => {
    dispatch(orderItemActions.setBrand(e.target.value))
 }
 const onServiceChange = e => {
    let selectedService = e.target.value;
    dispatch(orderItemActions.setService(selectedService))

    const selected = predataState.services.find(service=> service.name ===selectedService)

    dispatch(orderItemActions.setPrice(selected.rate))

 }
 const onSizeChange = e => {
    dispatch(orderItemActions.setSize(e.target.value))
 }

 const onAddItem = e => {
    e.preventDefault();
    dispatch(orderItemActions.setId(Math.random() * 10))
    dispatch(orderAction.addItem(orderItemState))
    dispatch(orderItemActions.reset())

 }


  return (
       <form className='card' style={{padding:'20px'}}>
            <div className="form-group row">
                <div className='col'>
                    <label>BRAND </label>
                    <select  className='form-control' value={orderItemState.brand} onChange={onBrandChange}>
                        <option></option>
                        {predataState.brands.map(option => (
                        <option key={option._id} value={option.name}>
                            {option.name}
                        </option>
                        ))}
                    </select>
               
                </div>
                <div className='col'>
                    <label>PICK A SERVICE </label>
                    <select  className='form-control' style={{width:'100%'}} value={orderItemState.service} onChange={onServiceChange}>
                        <option></option>
                        {predataState.services.map(option => (
                        <option key={option._id} value={option.name}>
                            {option.name}
                        </option>
                        ))}
                    </select>
               
                </div>
                <div className='col'>
                    <label htmlFor="size">SHOE SIZE</label>
                    <input className="form-control" value={orderItemState.size} onChange={onSizeChange} id="size" type="number" placeholder='Size...' name="Size" />
                </div>
                <div className='col'>
                    <br/>
                    <Button name={'ADD'} action={onAddItem} showSpinner={false}/>
                </div>
                
            </div>
        </form>
  )
}

export default OrderForm