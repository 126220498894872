import React from 'react'
import Button from './Button'
import Logo from './Logo'
import { useSelector,useDispatch } from 'react-redux'
import { customerActions } from '../Redux/CustomerSlice'
import { urls } from '../Strings/urls'
import { orderAction } from '../Redux/OrderSlice'
import { uiActions } from '../Redux/UiSlice'
import { notiAction } from '../Redux/notiSlice'
import { spinnerAction } from '../Redux/SpinnerSlice'

const Register = () => {
  const email_text = 'EMAIL ADDRESS (OPTIONAL)';


  const customerState = useSelector(state=> state.customer);
  const dispatch = useDispatch();

  const onFullNameChange = (e)=> {
    dispatch(customerActions.setFullName(e.target.value));

  }
  const onCellphoneChange = (e)=> {
    dispatch(customerActions.setCellphone(e.target.value));
    
  }
  const onEmailChange = (e)=> {
    dispatch(customerActions.setEmail(e.target.value));
  }

  const onRegister = (e)=>{
    e.preventDefault();

    const register = async () => {
        dispatch(spinnerAction.setIsLoading(true))
        let res = await fetch(urls.end_point+'/customers',{
            method:'POST',
            body: JSON.stringify({
                fullname: customerState.fullname,
                cellphone: customerState.cellphone,
                email: customerState.email
            }),
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
        })

        let data = await res.json();
        dispatch(spinnerAction.setIsLoading(false))
        let customer = data;
        console.log(customer);
        dispatch(customerActions.setCustomer({
            _id:customer._id,
            fullname: customer.fullname,
            cellphone:customer.cellphone,
            email:customer.email
        }))

        dispatch(notiAction.showNotification({
          open:true,
          message:"Registered Successfully!",
          type: 'success'
        }))

        setTimeout(() => {
            dispatch(notiAction.clearNotification())
        }, 5000);

        dispatch(orderAction.setCustomer(customer.fullname))

        dispatch(uiActions.setIsOrdering())
    }

    register().catch(error=>{
        dispatch(spinnerAction.setIsLoading(false))
        console.log(error)
        dispatch(notiAction.showNotification({
          open:true,
          message:"OOps, Something went wrong!",
          type: 'error'
        }))

        setTimeout(() => {
            dispatch(notiAction.clearNotification())
        }, 5000);

    })
  }

  return (
    <div className="form-box">
         <div className='text-center'>
         <Logo/>
         </div>
         
        <br/>
        <form>
            <div className="form-group">
                <label htmlFor="f_name">FULL NAME</label>
                <input className="form-control" id="f_name" value={customerState.fullname} onChange={onFullNameChange} type="text" placeholder='Your Full Name...' name="NameFull" />
            </div>
            <br/>
            <div className="form-group">
                <label htmlFor="cellphone">CELLPHONE NUMBER</label>
                <input className="form-control" id="cellphone" value={customerState.cellphone} onChange={onCellphoneChange} type="text" placeholder='Your Cellphone...' name="Cellphone" />
            </div>
            <br/>
            <div className="form-group">
                <label htmlFor="email">{email_text} </label>
                <input className="form-control" id="email"  value={customerState.email} onChange={onEmailChange} type="text" placeholder='Your Email Address' name="Email" />
            </div>
            <br/>
            <Button name={'REGISTER'} action={onRegister}/>
        </form>
    </div>
  )
}

export default Register