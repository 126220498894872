import React from 'react'
import Button from './Button'
import Logo from './Logo'
import OrderForm from './OrderForm'
import OrderItem from './OrderItem'
import { useSelector,useDispatch } from 'react-redux'
import { urls } from '../Strings/urls'
import { uiActions } from '../Redux/UiSlice'
import { searchActions } from '../Redux/SearchSlice'
import { orderAction } from '../Redux/OrderSlice'
import { spinnerAction } from '../Redux/SpinnerSlice'
import { notiAction } from '../Redux/notiSlice'
import { orderItemActions } from '../Redux/OrderItemSlice'

const Order = () => {


  const customerState = useSelector(state=>state.customer);
  const orderState = useSelector(state=>state.order);

  const dispatch = useDispatch();
  let total = 0;

  orderState.items.forEach(item => {
    total+=item.price;
  });

  const onComplete = e => {
     e.preventDefault();

     const complete = async () =>{

        dispatch(spinnerAction.setIsLoading(true))
        let res = await fetch(urls.end_point+'/orders',{
            method:'POST',
            body: JSON.stringify({
                customer: customerState.fullname,
                cellphone: customerState.cellphone,
                email: customerState.email,
                items: JSON.stringify(orderState.items)
            }),
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
        dispatch(notiAction.showNotification({
          open:true,
          message:"Your info is successfully captured!",
          type: 'success'
        }))

        setTimeout(() => {
            dispatch(notiAction.clearNotification())
        }, 5000);

        dispatch(spinnerAction.setIsLoading(false))
        let data = await res.json();

        console.log(data)

        dispatch(orderAction.clearItems())

        dispatch(orderItemActions.reset())

        dispatch(searchActions.setCellphone(''));

        dispatch(uiActions.setIsSearching())
     }

     complete().catch(error=>{
        dispatch(spinnerAction.setIsLoading(false))
        console.log(error)
        dispatch(notiAction.showNotification({
          open:true,
          message:"Someting went wrong",
          type: 'error'
        }))

        setTimeout(() => {
            dispatch(notiAction.clearNotification())
        }, 5000);
     })


  }

  return (
 
    

    <div className="order-box col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-10" >
         <div className='text-center'>
         <Logo/>
         <br/>
         <h4>Hi {customerState.fullname}, Welcome To Clean Kicks!</h4>
         </div>
         <OrderForm/>
         <table class="table">
            <thead>
                <tr>
                <th scope="col">Delete</th>
                <th scope="col">Brand</th>
                <th scope="col">Service</th>
                <th scope="col">Size</th>
                <th scope="col">Total</th>
                </tr>
            </thead>
            <tbody> 
                {orderState.items.map(item=> (
                     <OrderItem key={item.id} brand={item.brand} service={item.service} size={item.size} price={item.price} id={item.id}/>
                ))}
               
            </tbody>
            <tfoot>
                <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Estimate</th>
                    <th className='text-right' scope="col">N${total}</th>
                </tr>
                
            </tfoot>
        </table>
        
        <div className='row'>
          <div className='col'></div>
          <div className='col'>
            <br/>
            <Button action={onComplete} name={'COMPLETE'}/>
          </div>
          <div className='col'></div>
        </div>
       
    </div>
   

  )
}

export default Order