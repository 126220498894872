import { createSlice } from "@reduxjs/toolkit";

const customerSlice = createSlice({
    name: 'customer',
    initialState: {
        _id:'',
        fullname:'',
        cellphone:'',
        email:''
    },

    reducers: {

        setCustomer(state,action){
            state._id=action.payload._id;
            state.fullname=action.payload.fullname;
            state.cellphone=action.payload.cellphone;
            state.email=action.payload.email;
        },

        setFullName(state,action){
            state.fullname = action.payload;
        },
        setCellphone(state,action){
            state.cellphone = action.payload;
        },
        setEmail(state,action){
            state.email = action.payload;
        }
    }

})

export const customerActions = customerSlice.actions;

export default customerSlice;