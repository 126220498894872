import React, { useEffect } from 'react'
import Logo from './Logo'
import { useSelector,useDispatch } from 'react-redux'
import { predataActions } from '../Redux/preDataSlice';
import { urls } from '../Strings/urls';

const Admin = () => {
  const dispatch = useDispatch();
  const orders = useSelector(state=>state.predata.orders)
  
  useEffect(()=>{
    const getOrders = async ()=> {

      let res = await fetch(urls.end_point+'/orders');
      let data = await res.json();
      dispatch(predataActions.setOrders(data.reverse()))
    }

    getOrders().catch(err=>{
      console.log(err);
    })

  },[dispatch])

  return (
    <div className="order-box col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-10" >
    <div className='text-center'>
    <Logo/>
    
    </div>
    
    <table class="table">
       <thead>
           <tr>
           <th scope="col">Customer</th>
           <th scope="col">Cellphone</th>
           <th scope="col">Email</th>
           <th scope="col">Created At</th>
           <th scope="col">items</th>
           
           </tr>
       </thead>
       <tbody> 
           {orders.map(order=>(
                <tr>
                    <td>{order.customer}</td>
                    <td>{order.cell}</td>
                    <td>{order.email}</td>
                    <td>{order.created}</td>
                    <td>{order.items}</td>
                </tr>
           )) }
          
       </tbody>
       
   </table>
   
   
  
</div>
  )
}

export default Admin