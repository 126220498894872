
import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({

    name: 'ui',
    initialState: {
        isSearching:true,
        isRegistering:false,
        isOrdering:false
    },

    reducers: {
        setIsSearching(state){
            state.isSearching=true;
            state.isRegistering=false;
            state.isOrdering=false;
        },

        setIsRegistering(state){
            state.isSearching=false;
            state.isRegistering=true;
            state.isOrdering=false;
        },

        setIsOrdering(state){
            state.isSearching=false;
            state.isRegistering=false;
            state.isOrdering=true;
        }
        
    }

})

export const uiActions = uiSlice.actions;

export default uiSlice;