import React from 'react'
import {useDispatch } from 'react-redux'
import { orderAction } from '../Redux/OrderSlice';

const OrderItem = ({brand,service,size,price,id}) => {

    const dispatch = useDispatch();



  const onRemoveItem = (e) => {
     e.preventDefault()
     dispatch(orderAction.removeItem(id))
  }
  return (
    <tr>
        <th onClick={onRemoveItem} style={{color:'orange',cursor:'pointer'}}>X</th>
        <td>{brand}</td>
        <td>{service}</td>
        <td>{size}</td>
        <td>N$ {price}</td>
    </tr>
  )
}

export default OrderItem