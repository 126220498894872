import { createSlice } from "@reduxjs/toolkit";

const orderItemSlice = createSlice({
    name: 'orderItem',
    initialState: {
        id:'',
        brand:'',
        service:'',
        size:'',
        price:''
    },

    reducers: {
       setId(state,action){
         state.id = action.payload;
       },
       setBrand(state,action){
         state.brand = action.payload;
       },
       setService(state,action){
        state.service = action.payload;
       },
       setSize(state,action){
            state.size = action.payload;
       },
       setPrice(state,action){
        state.price = action.payload;
       },
       reset(state){
        state.id='';
        state.brand='';
        state.service='';
        state.size='';
        state.price=''
       }
    }

})

export const orderItemActions = orderItemSlice.actions;

export default orderItemSlice;