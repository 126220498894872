import { Alert } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { notiAction } from '../Redux/notiSlice';

const Notification = ({type,message}) => {
  const notification = useSelector(state=> state.noti.notification);
  const dispatch = useDispatch();
  const handleOnClose = () => {
      dispatch(notiAction.showNotification({
        open:false
      }))
  }
  return (
    <div style={{width:"50%", margin:'auto'}}>
        {notification.open && <Alert onClose={handleOnClose} variant="outlined" severity={type}>{message}</Alert>}
    </div>
  )
}

export default Notification