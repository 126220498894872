import React from 'react'
import { HashLoader } from 'react-spinners'
import { colors } from '../Strings/colors'
import { useSelector,useDispatch } from 'react-redux'

const Button = ({name,action,showSpinner=true}) => {

  const spinnerState = useSelector(state=> state.spinner);
  const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

  return (
    <div className='text-center'>
      
      {spinnerState.isLoading==false && <button onClick={action} className="btn btn-primary form-control" style={{backgroundColor:'#0a9fd9'}} >{name}</button>}
      
      {showSpinner && <HashLoader loading={spinnerState.isLoading}  cssOverride={override} color={colors.blue}/>}
    
    </div>
    
  )
}

export default Button