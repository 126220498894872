

import { configureStore } from '@reduxjs/toolkit'
import customerSlice from './CustomerSlice'
import notiSlice from './notiSlice'
import orderItemSlice from './OrderItemSlice'
import orderSlice from './OrderSlice'
import predataSlice from './preDataSlice'
import searchSlice from './SearchSlice'
import spinnerSlice from './SpinnerSlice'
import uiSlice from './UiSlice'


export const store = configureStore({
  reducer: {
    order: orderSlice.reducer,
    customer: customerSlice.reducer,
    search: searchSlice.reducer,
    ui: uiSlice.reducer,
    predata: predataSlice.reducer,
    orderitem: orderItemSlice.reducer,
    noti: notiSlice.reducer,
    spinner: spinnerSlice.reducer
  },
})