import React from 'react'
import { useSelector } from 'react-redux'
import Notification from './Notification';
import Order from './Order';
import Register from './Register';
import Search from './Search';

const Application = () => {
 
  const uiState = useSelector(state=> state.ui);
 

  const notification = useSelector(state => state.noti.notification)
  
  return (
    <div>
         {notification && <Notification type={notification.type}  message={notification.message}/>}
          {uiState.isSearching && <Search/>}
          {uiState.isRegistering && <Register/>}
          {uiState.isOrdering && <Order/>}
    </div>
  )
}

export default Application